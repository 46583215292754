/*------------------------------------------------
    ## Section title
------------------------------------------------*/
.section-title {
  margin-bottom: 40px;
  position: relative;
  .subtitle {
    font-weight: 600;
    font-size: 16px;
    color: var(--main-color);
    background: linear-gradient(
      180deg,
      rgba(41, 127, 54, 0) 15.22%,
      rgba(41, 127, 54, 0.0833333) 33.76%,
      rgba(41, 127, 54, 0.23) 58.49%,
      rgba(41, 127, 54, 0.39383) 79.25%,
      #297F36 100%
    );
    height: 46px;
    line-height: 46px;
    border-radius: 20px;
    display: inline-block;
    position: relative;
    z-index: 0;
    padding: 0 30px;
    margin-bottom: 19px;
    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      height: -webkit-fill-available;
      width: -webkit-fill-available;
      background: #fff;
      border-radius: 0 0 20px 20px;
      z-index: -1;
      margin: 0 2px 2px;
    }
    &.style-2 {
      // background: url(../img/wcu/bg-subtitle.png);
      //   background: url("~/assets/img/shape/bg-subtitle.png");
      background-size: 100% 100%;
      background-position: bottom;
      background-repeat: no-repeat;
      &:after {
        display: none;
      }
    }
  }
  .title {
    margin-bottom: 0;
    margin-top: 0;
    font-size: 40px;
    font-weight: 900;
    position: relative;
    z-index: 0;
  }
  .content {
    margin-top: 17px;
    margin-bottom: 0;
    &.left-line {
      border-left: 5px solid var(--main-color);
      padding-left: 50px;
    }
  }
  .small-title {
    margin-top: 18px;
    line-height: 36px;
  }
  .btn {
    margin-top: 30px;
  }
  &.style-white {
    .subtitle {
      color: #fff;
    }
    .title {
      color: #fff;
    }
    .content {
      color: #d6d6d6;
    }
    .small-title {
      color: #fff;
    }
  }
}
